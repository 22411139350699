import { IMetaDataOrigin, IMetaDataUpdate } from './index';
import { isEmpty, isNil } from 'lodash';

import styles from './styles.module.scss';
import { Space, Typography } from 'antd';
import { checkTaskSeparatedShift, convertStringWorkerToNumber } from 'helper';
import { textJP } from 'common/const';

import RowDate from './RowDate';
import RowMaxWorkerAndCertificate from './RowMaxWorkerAndCertificate';

interface IProps {
  dataUpdate?: IMetaDataUpdate;
  dataOrigin?: IMetaDataOrigin;
}

const valueNotChange = '変更なし';
const valueNotExists = 'なし';

const BodyHistoryUpdate = ({ dataUpdate = {}, dataOrigin = {} }: IProps) => {
  const isFirstTimeCreateTask = isEmpty(dataOrigin);

  const dataOriginConvert = convertStringWorkerToNumber({ ...dataOrigin }) as IMetaDataOrigin;
  const dataUpdateConvert = convertStringWorkerToNumber({ ...dataUpdate }) as IMetaDataUpdate;

  const valueDefault = isFirstTimeCreateTask ? valueNotExists : valueNotChange;

  const metadataCustomerUnitPriceCertificate = dataUpdateConvert?.metadataCustomerUnitPriceCertificate
    ? (JSON.parse(
        dataUpdateConvert?.metadataCustomerUnitPriceCertificate
      ) as IMetaDataOrigin['metadataCustomerUnitPriceCertificate'])
    : null;

  // check is update max-worker and certificate morning + afternoon
  const isUpdateCertificate = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorker =
    !isNil(dataUpdateConvert?.maxWorker) && dataUpdateConvert?.maxWorker !== dataOriginConvert?.maxWorker;

  // check is update max-worker and certificate morning
  const isUpdateCertificateMorning = !isNil(metadataCustomerUnitPriceCertificate?.morningShift);
  const isUpdateMaxWorkerMorning =
    !isNil(dataUpdateConvert?.maxWorkerMorning) &&
    dataUpdateConvert?.maxWorkerMorning !== dataOriginConvert?.maxWorkerMorning;

  // check is update max-worker and certificate afternoon
  const isUpdateCertificateAfternoon = !isNil(metadataCustomerUnitPriceCertificate?.afternoonShift);
  const isUpdateMaxWorkerAfternoon =
    !isNil(dataUpdateConvert?.maxWorkerAfternoon) &&
    dataUpdateConvert?.maxWorkerAfternoon !== dataOriginConvert?.maxWorkerAfternoon;

  // check is update max-worker and certificate evening
  const isUpdateCertificateEvening = !isNil(metadataCustomerUnitPriceCertificate?.eveningShift);
  const isUpdateMaxWorkerEvening =
    !isNil(dataUpdateConvert?.maxWorkerEvening) &&
    dataUpdateConvert?.maxWorkerEvening !== dataOriginConvert?.maxWorkerEvening;

  // check is update max-worker and certificate other
  const isUpdateCertificateOther = !isNil(metadataCustomerUnitPriceCertificate?.otherShift);
  const isUpdateMaxWorkerOther =
    !isNil(dataUpdateConvert?.maxWorkerOther) &&
    dataUpdateConvert?.maxWorkerOther !== dataOriginConvert?.maxWorkerOther;

  const isShowMaxWorkerMorningAndAfternoon = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorker)) {
      return false;
    }

    return isUpdateCertificate || isUpdateMaxWorker;
  };

  const isShowMaxWorkerMorning = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerMorning)) {
      return false;
    }

    return isUpdateCertificateMorning || isUpdateMaxWorkerMorning;
  };

  const isShowMaxWorkerAfternoon = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerAfternoon)) {
      return false;
    }

    return isUpdateCertificateAfternoon || isUpdateMaxWorkerAfternoon;
  };

  const isShowMaxWorkerEvening = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerEvening)) {
      return false;
    }

    return isUpdateCertificateEvening || isUpdateMaxWorkerEvening;
  };

  const isShowMaxWorkerOther = () => {
    // lần tạo đầu tiên và là công trường fix price thì không hiển thị max worker
    if (isFirstTimeCreateTask && isNil(dataUpdateConvert?.maxWorkerOther)) {
      return false;
    }

    return isUpdateCertificateOther || isUpdateMaxWorkerOther;
  };

  const isShowDataMaxWorker = [
    isShowMaxWorkerMorningAndAfternoon(),
    isShowMaxWorkerMorning(),
    isShowMaxWorkerAfternoon(),
    isShowMaxWorkerEvening(),
    isShowMaxWorkerOther(),
  ].some(Boolean);

  const isNewData = checkTaskSeparatedShift(dataUpdateConvert as any);

  const originShifts = [
    dataOriginConvert.morningShift ? textJP.shift.morningShift : null,
    dataOriginConvert.afternoonShift ? textJP.shift.afternoonShift : null,
    dataOriginConvert.eveningShift ? textJP.shift.eveningShift : null,
    dataOriginConvert.otherShift ? textJP.shift.otherShift : null,
  ]
    .filter(Boolean)
    .join(', ');

  // Nếu có data update thì ưu tiên lấy data update
  // Nếu không có data update thì lấy data origin
  const shiftsAfterUpdate = {
    morningShift: !isNil(dataUpdateConvert.morningShift)
      ? !!dataUpdateConvert.morningShift
      : !!dataOriginConvert.morningShift,
    afternoonShift: !isNil(dataUpdateConvert.afternoonShift)
      ? !!dataUpdateConvert.afternoonShift
      : !!dataOriginConvert.afternoonShift,
    eveningShift: !isNil(dataUpdateConvert.eveningShift)
      ? !!dataUpdateConvert.eveningShift
      : !!dataOriginConvert.eveningShift,
    otherShift: !isNil(dataUpdateConvert.otherShift) ? !!dataUpdateConvert.otherShift : !!dataOriginConvert.otherShift,
  };

  const updateShifts = [
    shiftsAfterUpdate.morningShift ? textJP.shift.morningShift : null,
    shiftsAfterUpdate.afternoonShift ? textJP.shift.afternoonShift : null,
    shiftsAfterUpdate.eveningShift ? textJP.shift.eveningShift : null,
    shiftsAfterUpdate.otherShift ? textJP.shift.otherShift : null,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* Note công việc */}
      {!!dataUpdateConvert?.description?.trim() && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>仕事の内容</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.description || valueNotExists} -&gt; </>}
            {!isNil(dataUpdateConvert?.description) ? dataUpdateConvert?.description : valueDefault}
          </div>
        </div>
      )}

      {/* Files thay đổi */}
      {!isNil(dataUpdateConvert?.files) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>アップロードされたファイル</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <Space direction="vertical" size={1}>
                  {dataOriginConvert?.files?.map((file, index) => (
                    <Typography.Link target="_blank" href={file.url} key={index}>
                      {file?.name}
                    </Typography.Link>
                  )) || valueNotExists}{' '}
                </Space>
                -&gt;
              </>
            )}

            {!isNil(dataUpdateConvert?.files) ? (
              <Space direction="vertical" size={1}>
                {dataUpdateConvert?.files?.map((file, index) => (
                  <Typography.Link target="_blank" href={file.url} key={index}>
                    {file?.name}
                  </Typography.Link>
                ))}
              </Space>
            ) : (
              valueDefault
            )}
          </div>
        </div>
      )}

      {/* Ca làm việc của task */}
      {[
        dataUpdateConvert?.afternoonShift,
        dataUpdateConvert?.morningShift,
        dataUpdateConvert?.eveningShift,
        dataUpdateConvert?.otherShift,
      ].some((item) => !isNil(item)) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>プロジェクト時間</div>
          <div className={styles.colFull}>
            {isFirstTimeCreateTask ? (
              updateShifts
            ) : (
              <>
                {originShifts} -&gt; {updateShifts}
              </>
            )}
          </div>
        </div>
      )}

      {/* Tên công việc */}
      {!isNil(dataUpdateConvert?.name) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>手配名</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.name || valueNotExists} -&gt; </>}
            {dataUpdateConvert?.name || valueDefault}
          </div>
        </div>
      )}

      {/* Số người cần thiết cho công việc  + Chứng chỉ khách hàng*/}
      {isShowDataMaxWorker && (
        <RowMaxWorkerAndCertificate
          dataOriginConvert={dataOriginConvert}
          dataUpdateConvert={dataUpdateConvert}
          isFirstTimeCreateTask={isFirstTimeCreateTask}
          isNewData={isNewData}
          shiftsAfterUpdate={shiftsAfterUpdate}
          metadataCustomerUnitPriceCertificate={
            metadataCustomerUnitPriceCertificate as IMetaDataOrigin['metadataCustomerUnitPriceCertificate']
          }
        />
      )}

      {/* note công trường */}
      {!isNil(dataUpdateConvert?.noteConstructionSite) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>プロジェクト概要・メモ</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && <>{dataOriginConvert?.noteConstructionSite || valueNotExists} -&gt; </>}

            {!isNil(dataUpdateConvert?.noteConstructionSite) ? dataUpdateConvert?.noteConstructionSite : valueDefault}
          </div>
        </div>
      )}

      {/* Ngày bắt đầu/ ngày kết thúc seri task */}
      {!isNil(dataUpdateConvert?.date) && (
        <RowDate
          dataOriginConvert={dataOriginConvert}
          dataUpdateConvert={dataUpdateConvert}
          isFirstTimeCreateTask={isFirstTimeCreateTask}
        />
      )}

      {/* Thời gian mong muốn của customer */}
      {(dataUpdateConvert?.startTimeDesire || dataUpdateConvert?.endTimeDesire) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>希望時間</div>
          <div className={styles.colFull}>
            {!isFirstTimeCreateTask && (
              <>
                <span>
                  {dataOriginConvert?.startTimeDesire || valueNotExists} :{' '}
                  {dataOriginConvert?.endTimeDesire || valueNotExists}
                </span>
                <span> -&gt; </span>
              </>
            )}

            <span>
              {dataUpdateConvert?.startTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)} :{' '}
              {dataUpdateConvert?.endTimeDesire || (isFirstTimeCreateTask ? valueNotExists : valueDefault)}
            </span>
          </div>
        </div>
      )}

      {!isNil(dataUpdateConvert?.constructionSiteNameOldUpdateProject) && (
        <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
          <div className={styles.col150px}>プロジェクト</div>
          <div className={styles.colFull}>{dataUpdateConvert?.constructionSiteNameOldUpdateProject}</div>
        </div>
      )}
    </div>
  );
};

export default BodyHistoryUpdate;
