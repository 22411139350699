import {
  IComment,
  IListVendorWorking,
  IParamsSearchStaffHaveTask,
  IParamsUpdateVendorWorking,
  IResListTask,
  ITermTaskWorker,
  IWorkersUpdate,
  TResCalendarWorkerReport,
  TResWorkerReportDetail,
} from 'common/interface';
import { axiosInstance, sendGet, sendPost, sendPut } from './axios';

export const getListConstructionCalendar = (params: any) => sendPost('/calendar/list-construction-sites', params);
export const getListTaskCalendar = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post('/calendar/list-task', params, {
    headers: { companyId },
  });

  return response?.data;
};

// export const getListTaskCalendarMultiCompany = (params: any) => sendPost('/nest/calendar/super-calendar', params);

export const getListTaskCalendarMultiCompany = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post<IResListTask>('/nest/calendar/super-calendar', params, {
    headers: { companyId },
  });

  return response?.data;
};

export const getListConstructionTaskCalendar = (params: any) => sendPost('calendar/list-construction-tasks', params);

export const getListConstructionTaskCalendarNest = (params: any) =>
  sendPost('/nest/calendar/super-calendar-constructions', params);

export const getCalendarWorkerReport = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post('/calendar/construction-site-worker-report', params, {
    headers: { companyId },
  });

  return response.data as TResCalendarWorkerReport;
};

export const getCalendarWorkerReportDetail = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post<TResWorkerReportDetail>(
    '/calendar/detail-construction-site-worker-report',
    params,
    {
      headers: { companyId },
    }
  );

  return response?.data;
};

export const getListNoteCalendar = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post<{ data: IComment[] }>('/calendar/list-note', params, {
    headers: { companyId },
  });

  return response?.data;
};
export const getListNoteCalendarNest = (params: any) =>
  sendPost<{ data: IComment[] }>('/nest/calendar/list-note', params);
export const createNoteCalendar = async (params: any, companyId?: number) => {
  const response = await axiosInstance.post('/calendar/create-note', params, {
    headers: { companyId },
  });

  return response?.data;
};
export const updateNoteCalendar = async (id: number, params: any, companyId?: number) => {
  const response = await axiosInstance.put(`/calendar/update-note/${id}`, params, { headers: { companyId } });

  return response?.data;
};

export const updateModeCalendar = (params: any) => sendPost('/v2/user/update-mode-calendar', params);

export const getShift = (startTime: string, endTime: string) =>
  sendGet(`/v2/task/shift?startTime=${startTime}&endTime=${endTime}`);

export const searchStaffHaveTask = (params: any) => sendGet('/v3/task/search-staff-have-task', params);

export const getListVendorWorking = async (params: { workingDay: string }, companyId?: number) => {
  const response = await axiosInstance.get<IListVendorWorking>('/v5/calendar/vendor-working', {
    params,
    headers: { companyId },
  });

  return response?.data;
};

export const updateVendorWorking = async (
  params: IParamsUpdateVendorWorking,
  workingDay: string,
  companyId?: number
) => {
  const response = await axiosInstance.put(`/v5/calendar/vendor-working?workingDay=${workingDay}`, params, {
    headers: { companyId },
  });

  return response?.data;
};

export const searchStaffHaveTaskNest = async (params: IParamsSearchStaffHaveTask, companyId?: number) => {
  const response = await axiosInstance.post('/nest/calendar/search-staff-have-task', params, {
    headers: { companyId },
  });

  return response.data;
};

// temporary alow update only worker
export const updateMultiTasks = async ({ workers }: { workers: IWorkersUpdate }) => {
  return sendPut('v2/task/update-multiple-task', workers);
};

export const searchStaffCalendar = async (params: IParamsSearchStaffHaveTask, companyId?: number) => {
  const response = await axiosInstance.post('/nest/calendar/search-staff', params, {
    headers: { companyId },
  });

  return response.data;
};
/* -------------------------------------------------------------------------- */
/*                        Drag and drop in the calendar                       */
/* -------------------------------------------------------------------------- */
export const updateTempTasks = async (params: ITermTaskWorker) => {
  return sendPut('nest/task/update-term-task-worker', params);
};

export const getTempTasks = async (params: { taskIds: number[] }) => {
  return sendGet('nest/task/get-term-task-worker', params);
};
