import React from 'react';
import { ITaskDetail } from 'common/interface';

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  taskDetail?: ITaskDetail;
}

export function getLabelDetailsTime(taskDetail?: ITaskDetail) {
  const isSameTimeStartAndEnd = taskDetail?.seriesFrom === taskDetail?.seriesTo;

  if (taskDetail?.workingDates?.length) {
    return '日付';
  }

  if (isSameTimeStartAndEnd) {
    return '日付';
  }

  return '開始日 ‐ 終了日';
}

const LabelDetailsTime = ({ taskDetail, ...rest }: IProps) => {
  return <div {...rest}>{getLabelDetailsTime(taskDetail)}</div>;
};

export default LabelDetailsTime;
