import { getImageFormUrl } from 'api/common';
import { PaperType } from 'common';
import { dateUtils } from 'common/dateUtils';
import { IPaper, IPaymentRequest } from 'common/interface';
import FileSaver from 'file-saver';
import { getDatePaperPdf, getPaperTypeName } from 'helper';
import JSZip from 'jszip';
import { isNil } from 'lodash';
import moment from 'moment';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { uploadFilePdf } from 'api/upload';

export function createCsvFile(textData: string) {
  // Split the text data into rows (lines)
  const rows = textData.split('\n');

  // Prepare the CSV content
  let csvContent = '';

  // Loop through each row
  rows.forEach((row) => {
    // Split the row into cells based on comma delimiter (",")
    const cells = row.split(',');

    // Add comma-separated cells and a new line character
    csvContent += cells.join(',') + '\n';
  });

  // Create a Blob object with the CSV content and appropriate MIME type
  const blob = new Blob(['\ufeff', csvContent], { type: 'text/csv;charset=utf-8' });

  // Create a downloadable link element (optional)
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = '所得税の算出率.csv';
  link.click(); // Simulate a click to trigger download (optional)
}

export const getNameFileExport = (paper: IPaper) => {
  const namePaper = getPaperTypeName(paper?.type, paper);
  const datePaper = getDatePaperPdf(paper);
  const nameCompanyCustomer = paper?.receiver?.companyCustomer?.name;
  const nameCustomer = paper?.receiver?.name;

  if ([PaperType.CREATE_PAYMENT_REQUEST_FOR_OTHER_WORKER, PaperType.PAYMENT_RECEIPT].includes(paper?.type)) {
    return [namePaper, nameCustomer, datePaper, paper?.no].filter((item) => !isNil(item)).join('_');
  }

  return [namePaper, nameCompanyCustomer, nameCustomer, datePaper, paper?.no].filter((item) => !isNil(item)).join('_');
};

export async function toDataURL(url: string) {
  const urlPreventCors = await getImageFormUrl(url);
  const blob = await fetch(urlPreventCors).then((res) => res.blob());
  return URL.createObjectURL(blob);
}

export const getBlobFromUrl = async (url: string) => {
  const urlPreventCors = await getImageFormUrl(url);
  const blob = await fetch(urlPreventCors).then((res) => res.blob());
  return blob;
};

export async function downloadFilesFromUrl({
  files,
  zipFilename,
}: {
  files: { url: string; fileName: string }[];
  zipFilename: string;
}) {
  const zip = new JSZip();

  // Download each file and add it to the zip
  for (const { fileName, url } of files) {
    const blob = await getBlobFromUrl(url);
    zip.file(fileName, blob);
  }

  // Generate the zip file and trigger download
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  return FileSaver.saveAs(zipBlob, zipFilename);
}

export async function downloadFileFromUrl({ url, fileName }: { url: string; fileName: string }) {
  const a = document.createElement('a');
  a.href = await toDataURL(url);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export const getNamePaymentFileZip = ({ payment }: { payment: IPaymentRequest }) => {
  const nameCreator = payment.creatorName;
  const dateCreator = dateUtils.currentDatabaseDate(moment(payment.createdAt));

  return `${[nameCreator, dateCreator].join('_')}`;
};

export const createPdfFromHtmlString = async ({
  htmlString,
  fileName,
}: {
  htmlString: string;
  fileName: string;
}): Promise<File> => {
  const options = {
    filename: fileName,
    margin: [2.5, 0, 2.5, 0],
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: 'mm', format: [210, 297], orientation: 'portrait' },
    pagebreak: { avoid: 'tr' },
  };

  // Create a promise to generate a Blob
  return new Promise((resolve, reject) => {
    html2pdf()
      .set(options)
      .from(htmlString)
      .toPdf()
      .outputPdf('blob')
      .then((blob: Blob) => {
        const file = new File([blob], fileName, { type: 'application/pdf' });
        resolve(file);
      })
      .catch((error: any) => reject(error));
  });
};

export const createUrlPdf = async ({ htmlString, fileName }: { htmlString: string; fileName?: string }) => {
  // get current time for file name
  const fileNameBaseTime = `${Date.now().toString()}.pdf`;

  // create pdf blob
  const pdfBlob = await createPdfFromHtmlString({ htmlString, fileName: fileName ?? fileNameBaseTime });

  // send pdf to s3
  const { domain, data } = await uploadFilePdf(pdfBlob);
  const urlPdf = `${domain}/${data?.[0]}`;

  return { pdfBlob, urlPdf };
};
