import { Button, Checkbox, Col, Form, message, Row, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  checkinCheckout,
  checkInOrCheckOutInTask,
  getListConstructionHaveTask,
  hasCheckin,
  hasCheckInTask,
  setTimeKeepingRest,
} from 'api/timekeeping';
import { SalaryType, TimeKeepingType, TYPE_PICKER, UserRole } from 'common';
import { dateUtils } from 'common/dateUtils';
import { IPayloadCheckInAndCheckOut, ITimekeepingRest } from 'common/interface';
import AccessControl from 'components/AccessControl';
import { CommonButton } from 'components/CommonButton';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { confirmPopup, CustomGeolocationError, getCurrentPosition, handleErrorMessage } from 'helper';
import useIsMounted from 'hooks/useIsMounted';
import useRoleId from 'hooks/useRoleId';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import iconInfo from 'assets/icons/InfomationIcon.svg';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import useProfile from 'hooks/useProfile';
import { SHIFT } from 'common/schedule';
import { messageConfirm, messageError, ROLES_CAN_HAVE_REST_TIME, textJP } from 'common/const';
import styles from './styles.module.scss';

interface IPropsTaskForm {
  type?: TimeKeepingType;
  handleClose: () => any;
  taskId?: number;
  isTimeKeepingInTask?: boolean;
  taskRoleId?: UserRole;
  taskSalaryType?: SalaryType;
  companyId?: number;
}

export const ModalTimeKeeping = ({
  handleClose,
  type,
  taskId,
  isTimeKeepingInTask,
  taskRoleId,
  taskSalaryType,
  companyId,
}: IPropsTaskForm) => {
  const isMounted = useIsMounted();
  const [form] = useForm();
  const roleId = useRoleId();
  const profile = useProfile();
  const [isOffice, setIsOffice] = useState(false);
  const [isOtherShift, setIsOtherShit] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const salaryType = isTimeKeepingInTask ? taskSalaryType : profile?.salary?.salaryType;
  const roleIdUser = isTimeKeepingInTask ? (taskRoleId as UserRole) : roleId;

  const [state, setState] = useSetState({
    loading: false,
    isCheckIn: true,
    timekeepingId: 0,
    canCheckInOutRest: isTimeKeepingInTask
      ? ROLES_CAN_HAVE_REST_TIME.includes(roleIdUser)
      : [UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER, UserRole.LEADER].includes(roleIdUser),
    isCheckingInOutReset: false,
  });

  //submit state
  const [submit, setSubmit] = useSetState({
    submitCheckIn: false,
    submitCheckInRest: false,
    submitCheckOut: false,
    submitCheckOutRest: false,
  });

  //handle show button
  const showButtonCancel = state.isCheckIn || (!state.isCheckIn && !state.canCheckInOutRest);
  const showButtonCheckIn = state.isCheckIn;
  const showButtonCheckInRest = !state.isCheckIn && state.canCheckInOutRest && !state.isCheckingInOutReset;
  const showButtonCheckOutRest = !state.isCheckIn && state.isCheckingInOutReset && state.canCheckInOutRest;

  const [rest, setRest] = useState<string[]>();
  const handleCheckInOutOtherShift = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setIsOtherShit(true);
    } else {
      setIsOtherShit(false);
    }
  };

  const handleCloseModalConfirm = () => {
    setIsShowModal(false);
  };

  const handleSubmitModalConfirm = () => {
    setIsShowModal(false);
    form.submit();
  };

  useEffect(() => {
    hanldeIsCheckin();
  }, []);

  const handleSubmitOption = async (key: string) => {
    const initState: any = {
      submitCheckIn: false,
      submitCheckInRest: false,
      submitCheckOut: false,
      submitCheckOutRest: false,
    };

    initState[key] = true;

    setSubmit(initState);

    if (key === 'submitCheckIn' && isOtherShift) {
      setIsShowModal(true);
    } else {
      form.submit();
    }
  };

  const hanldeIsCheckin = async () => {
    try {
      setState({
        loading: true,
      });

      let res = {} as any;

      if (isTimeKeepingInTask) {
        res = await hasCheckInTask(taskId!, companyId);
      } else {
        res = await hasCheckin();
      }

      const isHaveData = isTimeKeepingInTask ? Boolean(res?.data?.timeKeeping) : Boolean(res?.data);

      if (isHaveData) {
        const dataTimeKeeping = isTimeKeepingInTask ? res?.data?.timeKeeping : res?.data;

        setState({
          isCheckIn: false,
          timekeepingId: dataTimeKeeping?.id,
        });

        setIsOtherShit(dataTimeKeeping?.shift === SHIFT.OTHER);
        // thời gian nghỉ giải lao lần cuối
        const latestTimekeepingRest = dataTimeKeeping?.latestTimekeepingRest;

        // nếu đã check in thời gian nghỉ giải lao
        if (latestTimekeepingRest) {
          setState({
            isCheckingInOutReset: !!latestTimekeepingRest?.checkOut ? false : true,
          });

          const isCheckInOutRest = !latestTimekeepingRest?.checkOut;
          form.setFieldsValue({
            checkInRestOnDevice: isCheckInOutRest ? latestTimekeepingRest.checkIn : null,
          });
        }
        // có làm việc trong office
        setIsOffice(dataTimeKeeping?.isOffice ? true : false);
        form.setFieldsValue({
          checkIn: dataTimeKeeping?.checkIn,
          constructionSiteId: dataTimeKeeping?.constructionSiteId,
        });
        const timekeepingRests = dataTimeKeeping?.timekeepingRests ?? [];
        if (timekeepingRests.length) {
          const rests: string[] = [];
          timekeepingRests?.forEach((el: ITimekeepingRest, index: number) => {
            const rest = `${index + 1} 回 ：${el.checkIn} - ${el.checkOut || '--:--'}(${el.duration || 0}分)`;
            rests.push(rest);
          });
          setRest(rests);
        }
      } else {
        setState({
          isCheckIn: true,
        });
      }
    } catch (e) {
      handleErrorMessage(e);
    } finally {
      setState({
        loading: false,
      });
    }
  };

  // Hàm xử lý ConfirmPopup nếu không có vị trí
  // const confirmWithoutLocation = (params: any, errorMessage: string) => {
  //   confirmPopup({
  //     title: messageConfirm.askProceedWithoutLocation,
  //     message: <div>{errorMessage}</div>,
  //     onOk: async () => {
  //       try {
  //         await checkInOrCheckOutInTask(params, companyId);
  //         message.success('成功', 5);
  //       } catch (apiError: any) {
  //         handleErrorMessage(apiError);
  //       }
  //     },
  //     overlayClassName: styles.overlayConfirm,
  //   });
  // };

  const handleSubmit = async (values: any) => {
    try {
      setState({
        loading: true,
      });

      // Thời gian nghỉ
      if (submit.submitCheckInRest || submit.submitCheckOutRest) {
        if (isTimeKeepingInTask) {
          await setTimeKeepingRest({ timekeepingId: state.timekeepingId }, companyId);
        } else {
          await setTimeKeepingRest({ timekeepingId: state.timekeepingId });
        }
      }

      // Check in và check out
      if (submit.submitCheckIn || submit.submitCheckOut) {
        if (isTimeKeepingInTask) {
          let params = { taskId: taskId! } as IPayloadCheckInAndCheckOut;

          try {
            // get location of user check in
            const position = await getCurrentPosition();
            const { latitude, longitude } = position.coords;
            params.lat = latitude;
            params.lng = longitude;
          } catch (error: any) {}

          await checkInOrCheckOutInTask(params, companyId);
          message.success('成功', 5);
        }
        // logic check in out cũ
        else {
          const params = isOtherShift
            ? {
                constructionSiteId: values?.constructionSiteId,
                isOffice: isOffice ? 1 : 0,
                shift: SHIFT.OTHER,
              }
            : { constructionSiteId: values?.constructionSiteId, isOffice: isOffice ? 1 : 0 };

          await checkinCheckout(params);
          message.success('成功', 5);
        }
      }

      handleClose();
    } catch (error: any) {
      handleErrorMessage(error);
    } finally {
      if (isMounted?.current) {
        setState({
          loading: false,
        });
      }
    }
  };

  const [searchConstructionSiteParam, setSearchConstructionSiteParam] = useSetState({
    date:
      type === TimeKeepingType.REQUEST_OT || type === TimeKeepingType.REQUEST_OT_FOR_OTHER_USER
        ? [dateUtils.getDateToString(new Date()), dateUtils.getDateToString(moment(new Date()).subtract(1, 'day'))]
        : [dateUtils.getDateToString(new Date())],
    pageSize: 20,
    keyword: '',
  });
  const { data: listConstructionSite } = useQuery(
    ['list-construction-site-of-leader', searchConstructionSiteParam],
    () => getListConstructionHaveTask(searchConstructionSiteParam).then((res: any) => res?.data),
    {
      enabled: roleIdUser === UserRole.LEADER,
    }
  );

  const handleSearchConstructionSite = useCallback(
    debounce((keyword: string) => setSearchConstructionSiteParam({ keyword }), 300),
    []
  );

  const handleCheckIsOffice = (e: any) => {
    setIsOffice(e.target.checked);

    if (e.target.checked) {
      form.setFieldsValue({
        constructionSiteId: null,
      });
    }
  };

  return (
    <CommonModal
      width={720}
      handleClose={handleClose}
      isModalVisible
      title={'打刻 '}
      footer={null}
      closable
      maskClosable={false}
    >
      <CommonModal
        isModalVisible={isShowModal}
        title={''}
        okText="はい"
        cancelText="いいえ"
        titleCenter
        handleClose={handleCloseModalConfirm}
        handleSubmit={handleSubmitModalConfirm}
      >
        <div className="mt-20" style={{ textAlign: 'center', fontWeight: '700', fontSize: '18px', lineHeight: '20px' }}>
          オプションで打刻を実行しようとしています。
          <br />
          続行しますか。
        </div>
      </CommonModal>

      <Form
        layout="vertical"
        form={form}
        scrollToFirstError={true}
        onFinish={handleSubmit}
        initialValues={{ workingDay: moment() }}
        onValuesChange={(changedValues, allValues) => {
          if ('constructionSiteId' in changedValues && roleIdUser === UserRole.LEADER) {
            const isHasTaskOtherShift = listConstructionSite?.find(
              (el: any) => el.id === changedValues.constructionSiteId
            )?.hasTaskOtherShift;

            setIsOtherShit(isHasTaskOtherShift);
          }
        }}
      >
        <Form.Item
          label=""
          name="workingDay"
          rules={[
            {
              required: true,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
          className="mb-10"
        >
          {/* <CommonDatepicker
            disabledDate={(date) => date.isAfter(today, 'day') || date.isBefore(today, 'day')}
            disabled={type === TimeKeepingType.REQUEST}
          />  */}
          <div className="d-flex">
            <div> 日付 : {moment().format('YYYY/MM/DD')}</div>
            <div className="ml-50" style={{ color: 'red' }}>
              現在時刻: {moment().format('HH:mm')}
            </div>
          </div>
        </Form.Item>
        {salaryType === SalaryType.SHIFT_SALARY && roleIdUser !== UserRole.LEADER && (
          <Col span={24} className="mb-10">
            <Checkbox onChange={handleCheckInOutOtherShift} checked={isOtherShift} disabled={state.isCheckIn === false}>
              オプション
            </Checkbox>
            <Tooltip title={'オプションで打刻を実行する'} placement="bottomLeft">
              <img width="13" height="13" src={iconInfo} alt="" />
            </Tooltip>
          </Col>
        )}
        <AccessControl accessibleRoles={[UserRole.LEADER]}>
          {/* <Col span={24} className="mb-10">
            <Checkbox onChange={handleCheckIsOffice} disabled={state.isCheckIn === false} checked={isOffice}>
              オフィス内
            </Checkbox>
          </Col> */}
          {salaryType === SalaryType.SHIFT_SALARY && (
            <Col span={24} className="mb-10">
              <Checkbox
                onChange={handleCheckInOutOtherShift}
                checked={isOtherShift}
                disabled={state.isCheckIn === false}
              >
                オプション
              </Checkbox>
              <Tooltip title={'オプションで打刻を実行する'} placement="bottomLeft">
                <img width="13" height="13" src={iconInfo} alt="" />
              </Tooltip>
            </Col>
          )}
          <Form.Item label="プロジェクト名" name="constructionSiteId" labelAlign="left">
            <CommonSelect
              filterOption={false}
              onSearch={handleSearchConstructionSite}
              optionLabel="name"
              options={listConstructionSite || []}
              disabled={state.isCheckIn === false || isOffice}
            />
          </Form.Item>
        </AccessControl>

        <Row gutter={10}>
          <Col xs={12}>
            <Form.Item
              label="開始時刻"
              name="checkIn"
              rules={[
                {
                  required: false,
                  message: '全ての項目を入力してください。',
                },
              ]}
              labelAlign="left"
            >
              <CommonDatepicker value={'23:00'} picker={TYPE_PICKER.TIME} disabled placeholderText="--:--" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="終了時刻" name="checkOut" dependencies={['checkOut']} labelAlign="left">
              <CommonDatepicker picker={TYPE_PICKER.TIME} disabled={true} placeholderText="--:--" />
            </Form.Item>
          </Col>
          {!state.isCheckIn && state.canCheckInOutRest && (
            <>
              <Col xs={12}>
                <Form.Item
                  label="休憩開始時刻"
                  name="checkInRestOnDevice"
                  rules={[
                    {
                      required: false,
                      message: '全ての項目を入力してください。',
                    },
                  ]}
                  labelAlign="left"
                >
                  <CommonDatepicker picker={TYPE_PICKER.TIME} disabled placeholderText="--:--" />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label="休憩終了時刻" name="checkOutRestOnDevice" labelAlign="left">
                  <CommonDatepicker picker={TYPE_PICKER.TIME} disabled={true} placeholderText="--:--" />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row style={{ marginBottom: '20px' }}>{!!rest?.length && rest?.map((el) => <Col span={24}>{el}</Col>)}</Row>
        <Row gutter={10}>
          {/* left button */}
          {showButtonCancel ? (
            <Col xs={12}>
              <Button block htmlType="button" className="btn-outline-timekeeping" onClick={handleClose}>
                キャンセル
              </Button>
            </Col>
          ) : (
            <Col xs={12}>
              <CommonButton
                title="退勤"
                htmlType="submit"
                block
                className="btn-outline-timekeeping-red"
                disabled={state?.loading}
                onClick={() => handleSubmitOption('submitCheckOut')}
              />
            </Col>
          )}
          {/* right button */}
          {showButtonCheckIn ? (
            <Col xs={12}>
              <CommonButton
                title="出勤 "
                block
                htmlType="button"
                className="btn-outline-timekeeping-green"
                disabled={state?.loading}
                onClick={() => handleSubmitOption('submitCheckIn')}
              />
            </Col>
          ) : showButtonCheckInRest ? (
            <Col xs={12}>
              <CommonButton
                title={textJP.btn.breakStarts}
                block
                htmlType="submit"
                className="btn-outline-timekeeping-green"
                disabled={state?.loading}
                onClick={() => handleSubmitOption('submitCheckInRest')}
              />
            </Col>
          ) : showButtonCheckOutRest ? (
            <Col xs={12}>
              <CommonButton
                title="休憩終了"
                block
                htmlType="submit"
                className="btn-outline-timekeeping-dark-red"
                onClick={() => handleSubmitOption('submitCheckOutRest')}
                disabled={state?.loading}
              />
            </Col>
          ) : (
            <Col xs={12}>
              <CommonButton
                title="退勤 "
                htmlType="submit"
                block
                style={{
                  marginLeft: '0',
                  backgroundColor: 'white',
                  borderRadius: '5px',
                  border: '1px solid red',
                }}
                className="btn-outline-timekeeping-red"
                disabled={state?.loading}
                onClick={() => handleSubmitOption('submitCheckOut')}
              />
            </Col>
          )}
        </Row>
      </Form>
    </CommonModal>
  );
};
