import {
  IBodyUpdateConfirmInforPaper,
  IBodyUpdatePaymentStatus,
  IListCostConstructorSite,
  TResListPaper,
  TResListPaperByCustomer,
} from './../common/interface';
import axios from 'axios';
import { IBadge, IDeleteUserPaper } from 'common/interface';
import configs from 'config';
import { axiosInstance, sendGet, sendPost, sendPut } from './axios';
import { CommonStatus } from 'common';

export const listPaper = (payload: any) => sendPost<TResListPaper>('/paper/list-paper', payload);
export const detailPaper = (id: number) => sendPost(`/paper/${id}`);
export const receiveCompany = (payload: any) => sendPost('/paper/receiver-company', payload);
export const sendCompany = () => sendPost('/paper/send-company');
export const createPaper = (payload: any) => sendPost('/paper/create-paper', payload);
export const updatePaper = (id: number, payload: any) => sendPut(`/paper/${id}/update-status`, payload);
export const downloadPaper = (payload: any) => sendPost('paper/test-paper', payload);

export const createPaymentRequest = (payload: any, id: number) => sendPost(`/paper/create-paper/${id}`, payload);
export const getConstructionSiteCustomer = (payload: any, id: any) =>
  sendPost(`/paper/search-by-customer/${id}`, payload);

export const getRandomPaperNo = () => sendGet('/paper/paper-no');
export const getDetailPaperByNo = (no: any) => sendGet(`/paper/paper-details-by-no/${no}`);
export const getDetailPaperById = (id: number) => sendGet(`/paper/paper-details/${id}`);
export const searchCompanyUser = (payload: any) => sendPost('/paper/search-company-user', payload);

export const searchCompanyUserWithCompany = async (payload: any, companyId?: number) => {
  const response = await axiosInstance.post('/paper/search-company-user', payload, {
    headers: { companyId },
  });

  return response?.data;
};

export const searchPaperByOtherWorker = (payload: any, id: number) =>
  sendPost(`/paper/search-by-other-worker/${id}`, payload);
export const updatePaperStatus = (id: number, payload: any) => sendPut(`/paper/update-paper-status/${id}`, payload);

export const approveRequestForOtherWorker = (id: number, payload: any) =>
  sendPost(`/paper/other-worker-approve-paper/${id}`, payload);

export const downloadFile = async (content: string, title: string) => {
  await axios({
    baseURL: configs.API_DOMAIN,
    url: '/paper/download-pdf',
    method: 'POST',
    data: {
      html: content,
    },
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title}.pdf`);
    document.body.appendChild(link);
    link.click();
  });
};

// V2
export const updatePaymentStatus = ({ id, payload }: { id: number; payload: IBodyUpdatePaymentStatus }) =>
  sendPut(`/v2/paper/payment-status/${id}`, payload);
export const updateDraftPaper = (id: number, payload: any) => sendPut(`/v2/paper/${id}`, payload);

export interface IPaper {
  constructionSiteId: number;
  type: number;
  companySendId: number;
  companySendType: number;
  companyReceiveId: number;
  companyReceiveType: number;
  title: string;
  content: string;
}

// v4

export interface IGetOtherMissingV5 {
  otherWorkerIds: number[];
}

export interface IGetCompanyMissing {
  customerIds: number[];
}
export const getOtherMissing = () => sendGet('/v4/paper/list-other-worker-missing-paper');
export const getCompanyMissing = (params?: any) => sendGet('/v4/paper/list-company-missing-paper', params);
export const deletePaperMissing = (params: IDeleteUserPaper) => sendPut('/v4/paper/update-view-missing-paper', params);
export const getCountPaper = (payload: IBadge) => sendGet(`/v4/paper/count-paper`, payload);

export const getConstructionSiteCustomerV4 = (payload: any) =>
  sendPost<TResListPaperByCustomer>('/v4/paper/search-by-customers', payload);

export const checkIsTask = (userIds: any) => sendPost('/v4/paper/check-is-tax-list-member', { userIds });

export const getListCostConstructorSite = (paperId: number) =>
  sendGet<IListCostConstructorSite>(`/v5/paper/list-cost-per-construction-site/${paperId}`);

export const updateConfirmInforPaper = ({ paperId, body }: { paperId: string; body: IBodyUpdateConfirmInforPaper }) =>
  sendPut(`V5/paper/update-confirmation-info/${paperId}`, body);

export const getOtherMissingV5 = (params: IGetOtherMissingV5) =>
  sendGet('/v5/paper/list-other-worker-missing-paper', params);

export const vendorConfirmPaper = (paperId: string | number, body: { isVendorConfirm: CommonStatus }) =>
  sendPut(`/v6/paper/${paperId}/vendor-confirm`, body);

export const backRejectToDraft = (paperId: string | number) => sendPut(`/v6/paper/back-reject-to-draft/${paperId}`);

export const updateUrlPdfPaper = (payload: { dataPaperPdf: { urlPdf: string; id: number }[] }) =>
  sendPut('/nest/paper/update-url-pdf', payload);
