import { titlesShift } from 'common/const';
import { IMetaDataOrigin, IMetaDataUpdate } from '.';
import styles from './styles.module.scss';
import { isNil, omitBy } from 'lodash';
import { memo } from 'react';

interface IProps {
  dataUpdateConvert: IMetaDataUpdate;
  dataOriginConvert: IMetaDataOrigin;
  isFirstTimeCreateTask: boolean;
  metadataCustomerUnitPriceCertificate?: IMetaDataOrigin['metadataCustomerUnitPriceCertificate'];
  isNewData?: boolean;
  shiftsAfterUpdate?: {
    morningShift?: boolean;
    afternoonShift?: boolean;
    eveningShift?: boolean;
    otherShift?: boolean;
  };
}

const RowMaxWorkerAndCertificate = ({
  dataOriginConvert,
  isFirstTimeCreateTask,
  dataUpdateConvert,
  metadataCustomerUnitPriceCertificate,
  isNewData,
  shiftsAfterUpdate,
}: IProps) => {
  const maxWorkerAndCertificateOrigin = () => {
    /* --------------------------------- evening -------------------------------- */
    const maxWorkerEvening =
      !isNil(dataOriginConvert?.maxWorkerEvening) && !!dataOriginConvert.eveningShift
        ? `${titlesShift.eveningShift} : ${dataOriginConvert?.maxWorkerEvening}`
        : '';
    const certificateEvening = dataOriginConvert?.metadataCustomerUnitPriceCertificate?.eveningShift
      ?.map((item) => `${item.title} : ${item.workers}`)
      .join(', ');
    const maxWorkerAndCertificateEvening = `${maxWorkerEvening} ${certificateEvening ? `(${certificateEvening})` : ''}`;

    /* ---------------------------------- other --------------------------------- */
    const maxWorkerOther =
      !isNil(dataOriginConvert?.maxWorkerOther) && !!dataOriginConvert.otherShift
        ? `${titlesShift.otherShift} : ${dataOriginConvert?.maxWorkerOther}`
        : '';
    const certificateOther = dataOriginConvert?.metadataCustomerUnitPriceCertificate?.otherShift
      ?.map((item) => `${item.title} : ${item.workers}`)
      .join(', ');
    const maxWorkerAndCertificateOther = `${maxWorkerOther} ${certificateOther ? `(${certificateOther})` : ''}`;

    if (!isNewData) {
      /* -------------------------- morning and afternoon ------------------------- */
      const maxWorkerMorningAndAfternoon =
        !isNil(dataOriginConvert?.maxWorker) && !!dataOriginConvert.morningShift
          ? `${titlesShift.allDay}：${dataOriginConvert?.maxWorker}`
          : '';
      const certificateMorning = dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift
        ?.map((item) => `${item.title} : ${item.workers}`)
        ?.join(', ');

      const maxWorkerAndCertificateMorningAndAfternoon = `${maxWorkerMorningAndAfternoon} ${
        certificateMorning ? `(${certificateMorning})` : ''
      }`;

      return [maxWorkerAndCertificateMorningAndAfternoon, maxWorkerAndCertificateEvening, maxWorkerAndCertificateOther]
        .filter((item) => item.trim())
        .join(', ');
    }

    /* --------------------------------- morning -------------------------------- */
    const maxWorkerMorning =
      !isNil(dataOriginConvert?.maxWorkerMorning) && !!dataOriginConvert.morningShift
        ? `${titlesShift.morningShift} : ${dataOriginConvert?.maxWorkerMorning}`
        : '';
    const certificateMorning = dataOriginConvert?.metadataCustomerUnitPriceCertificate?.morningShift
      ?.map((item) => `${item.title} : ${item.workers}`)
      ?.join(', ');
    const maxWorkerAndCertificateMorning = `${maxWorkerMorning} ${certificateMorning ? `(${certificateMorning})` : ''}`;

    /* -------------------------------- afternoon ------------------------------- */
    const maxWorkerAfternoon =
      !isNil(dataOriginConvert?.maxWorkerAfternoon) && !!dataOriginConvert.afternoonShift
        ? `${titlesShift.afternoonShift} : ${dataOriginConvert?.maxWorkerAfternoon}`
        : '';
    const certificateAfternoon = dataOriginConvert?.metadataCustomerUnitPriceCertificate?.afternoonShift
      ?.map((item) => `${item.title} : ${item.workers}`)
      ?.join(', ');
    const maxWorkerAndCertificateAfternoon = `${maxWorkerAfternoon} ${
      certificateAfternoon ? `(${certificateAfternoon})` : ''
    }`;

    return [
      maxWorkerAndCertificateMorning,
      maxWorkerAndCertificateAfternoon,
      maxWorkerAndCertificateEvening,
      maxWorkerAndCertificateOther,
    ]
      .filter((item) => item.trim())
      .join(', ');
  };

  const maxWorkerAndCertificateAfterUpdateObj = {
    maxWorkerShift: !isNil(dataUpdateConvert.maxWorker) ? dataUpdateConvert.maxWorker : dataOriginConvert.maxWorker,
    maxWorkerMorningShift: !isNil(dataUpdateConvert.maxWorkerMorning)
      ? dataUpdateConvert.maxWorkerMorning
      : dataOriginConvert.maxWorkerMorning,
    maxWorkerAfternoonShift: !isNil(dataUpdateConvert.maxWorkerAfternoon)
      ? dataUpdateConvert.maxWorkerAfternoon
      : dataOriginConvert.maxWorkerAfternoon,
    maxWorkerEveningShift: !isNil(dataUpdateConvert.maxWorkerEvening)
      ? dataUpdateConvert.maxWorkerEvening
      : dataOriginConvert.maxWorkerEvening,
    maxWorkerOtherShift: !isNil(dataUpdateConvert.maxWorkerOther)
      ? dataUpdateConvert.maxWorkerOther
      : dataOriginConvert.maxWorkerOther,
    certificateMorningShift: !isNil(metadataCustomerUnitPriceCertificate?.morningShift)
      ? metadataCustomerUnitPriceCertificate?.morningShift
      : dataOriginConvert.metadataCustomerUnitPriceCertificate?.morningShift,
    certificateAfternoonShift: !isNil(metadataCustomerUnitPriceCertificate?.afternoonShift)
      ? metadataCustomerUnitPriceCertificate?.afternoonShift
      : dataOriginConvert.metadataCustomerUnitPriceCertificate?.afternoonShift,
    certificateEveningShift: !isNil(metadataCustomerUnitPriceCertificate?.eveningShift)
      ? metadataCustomerUnitPriceCertificate?.eveningShift
      : dataOriginConvert.metadataCustomerUnitPriceCertificate?.eveningShift,
    certificateOtherShift: !isNil(metadataCustomerUnitPriceCertificate?.otherShift)
      ? metadataCustomerUnitPriceCertificate?.otherShift
      : dataOriginConvert.metadataCustomerUnitPriceCertificate?.otherShift,
  };

  const maxWorkerAndCertificateAfterUpdate = omitBy(maxWorkerAndCertificateAfterUpdateObj, isNil);

  const maxWorkerAndCertificateUpdate = () => {
    /* --------------------------------- evening -------------------------------- */
    const maxWorkerEvening =
      !isNil(maxWorkerAndCertificateAfterUpdate?.maxWorkerEveningShift) && shiftsAfterUpdate?.eveningShift
        ? `${titlesShift.eveningShift} : ${maxWorkerAndCertificateAfterUpdate?.maxWorkerEveningShift}`
        : '';
    const certificateEvening = shiftsAfterUpdate?.eveningShift
      ? (maxWorkerAndCertificateAfterUpdate.certificateEveningShift as any[])
          ?.map((item) => `${item.title} : ${item.workers}`)
          .join(', ')
      : '';
    const maxWorkerAndCertificateEvening = `${maxWorkerEvening} ${certificateEvening ? `(${certificateEvening})` : ''}`;

    /* ---------------------------------- other --------------------------------- */
    const maxWorkerOther =
      !isNil(maxWorkerAndCertificateAfterUpdate?.maxWorkerOtherShift) && shiftsAfterUpdate?.otherShift
        ? `${titlesShift.otherShift} : ${maxWorkerAndCertificateAfterUpdate?.maxWorkerOtherShift}`
        : '';
    const certificateOther = shiftsAfterUpdate?.otherShift
      ? (maxWorkerAndCertificateAfterUpdate.certificateOtherShift as any[])
          ?.map((item) => `${item.title} : ${item.workers}`)
          .join(', ')
      : null;
    const maxWorkerAndCertificateOther = `${maxWorkerOther} ${certificateOther ? `(${certificateOther})` : ''}`;

    if (!isNewData) {
      /* -------------------------- morning and afternoon ------------------------- */
      const maxWorkerMorningAndAfternoon = !isNil(maxWorkerAndCertificateAfterUpdate?.maxWorkerShift)
        ? `${titlesShift.allDay}：${maxWorkerAndCertificateAfterUpdate?.maxWorkerShift}`
        : '';
      const certificateMorning = (maxWorkerAndCertificateAfterUpdate.certificateMorningShift as any[])
        ?.map((item) => `${item.title} : ${item.workers}`)
        ?.join(', ');

      const certificateAfternoon = (maxWorkerAndCertificateAfterUpdate.certificateAfternoonShift as any[])
        ?.map((item) => `${item.title} : ${item.workers}`)
        ?.join(', ');

      const certificateMorningAndAfternoon = [certificateMorning, certificateAfternoon].join(', ');
      const maxWorkerAndCertificateMorningAndAfternoon = `${maxWorkerMorningAndAfternoon} ${
        certificateMorningAndAfternoon ? `(${certificateMorningAndAfternoon})` : ''
      }`;

      return [maxWorkerAndCertificateMorningAndAfternoon, maxWorkerAndCertificateEvening, maxWorkerAndCertificateOther]
        .filter((item) => item.trim())
        .join(', ');
    }

    /* --------------------------------- morning -------------------------------- */
    const maxWorkerMorning =
      !isNil(maxWorkerAndCertificateAfterUpdate?.maxWorkerMorningShift) && shiftsAfterUpdate?.morningShift
        ? `${titlesShift.morningShift} : ${maxWorkerAndCertificateAfterUpdate?.maxWorkerMorningShift}`
        : '';
    const certificateMorning = shiftsAfterUpdate?.morningShift
      ? (maxWorkerAndCertificateAfterUpdate.certificateMorningShift as any[])
          ?.map((item) => `${item.title} : ${item.workers}`)
          ?.join(', ')
      : null;
    const maxWorkerAndCertificateMorning = `${maxWorkerMorning} ${certificateMorning ? `(${certificateMorning})` : ''}`;

    /* -------------------------------- afternoon ------------------------------- */
    const maxWorkerAfternoon =
      !isNil(maxWorkerAndCertificateAfterUpdate?.maxWorkerAfternoonShift) && shiftsAfterUpdate?.afternoonShift
        ? `${titlesShift.afternoonShift} : ${maxWorkerAndCertificateAfterUpdate?.maxWorkerAfternoonShift}`
        : '';
    const certificateAfternoon = shiftsAfterUpdate?.afternoonShift
      ? (maxWorkerAndCertificateAfterUpdate.certificateAfternoonShift as any[])
          ?.map((item) => `${item.title} : ${item.workers}`)
          ?.join(', ')
      : null;
    const maxWorkerAndCertificateAfternoon = `${maxWorkerAfternoon} ${
      certificateAfternoon ? `(${certificateAfternoon})` : ''
    }`;

    return [
      maxWorkerAndCertificateMorning,
      maxWorkerAndCertificateAfternoon,
      maxWorkerAndCertificateEvening,
      maxWorkerAndCertificateOther,
    ]
      .filter((item) => item.trim())
      .join(', ');
  };

  return (
    <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
      <div className={styles.col150px}>人工</div>
      <div className={styles.colFull}>
        {isFirstTimeCreateTask ? (
          maxWorkerAndCertificateUpdate()
        ) : (
          <>
            {maxWorkerAndCertificateOrigin()} -&gt; <div>{maxWorkerAndCertificateUpdate()}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(RowMaxWorkerAndCertificate);
