import styles from './styles.module.scss';
import moment from 'moment';
import { IMetaDataOrigin, IMetaDataUpdate } from '.';
import { memo } from 'react';
import classNames from 'classnames';

interface IProps {
  dataUpdateConvert: IMetaDataUpdate;
  dataOriginConvert: IMetaDataOrigin;
  isFirstTimeCreateTask: boolean;
}

const RowDate = ({ dataOriginConvert, isFirstTimeCreateTask, dataUpdateConvert }: IProps) => {
  const isUpdateTimeToSameDate = dataUpdateConvert.date?.from === dataUpdateConvert.date?.to;
  const isDateOriginSameDate = dataOriginConvert.date?.from === dataOriginConvert.date?.to;
  const dateOrigin = isDateOriginSameDate
    ? [dataOriginConvert?.date?.from ? moment(dataOriginConvert.date.from).format('YYYY/MM/DD') : '']
        .filter(Boolean)
        .join(' - ')
    : [
        dataOriginConvert?.date?.from ? moment(dataOriginConvert.date.from).format('YYYY/MM/DD') : '',
        dataOriginConvert?.date?.to ? moment(dataOriginConvert.date.to).format('YYYY/MM/DD') : '',
      ]
        .filter(Boolean)
        .join(' - ');

  const dateUpdate = isUpdateTimeToSameDate
    ? [dataUpdateConvert?.date?.from ? moment(dataUpdateConvert.date.from).format('YYYY/MM/DD') : '']
        .filter(Boolean)
        .join(' - ')
    : [
        dataUpdateConvert?.date?.from ? moment(dataUpdateConvert.date.from).format('YYYY/MM/DD') : '',
        dataUpdateConvert?.date?.to ? moment(dataUpdateConvert.date.to).format('YYYY/MM/DD') : '',
      ]
        .filter(Boolean)
        .join(' - ');

  return (
    <div style={{ width: '100%', display: 'flex', gap: '20px' }}>
      <div className={styles.col150px}>{!isUpdateTimeToSameDate ? '開始日 ‐ 終了日' : '日付'} </div>

      <div className={classNames(styles.colFull, styles.colDate)}>
        {isFirstTimeCreateTask ? (
          dateUpdate
        ) : (
          <>
            {dateOrigin} -&gt; {dateUpdate}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(RowDate);
