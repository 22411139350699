import { updateTempTasks } from 'api/calendar';
import { UserRole } from 'common';
import {
  IItemDropWorker,
  ITask,
  ITaskTempRes,
  ITermTaskWorker,
  IUnitPriceWorker,
  IVendor,
  IWorker,
  TaskTemp,
  TShift,
  VendorWorking,
} from 'common/interface';
import { isNil, set } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface ITempTask {
  taskId: number;
  companyId: number;
  workers: {
    morningShift: IWorker[];
    afternoonShift: IWorker[];
    eveningShift: IWorker[];
    otherShift: IWorker[];
    unitPrice: IUnitPriceWorker[];
  };
  vendors: {
    list: TItemVendor[];
    morningShift: { userId: number; maxWorker: number }[];
    afternoonShift: { userId: number; maxWorker: number }[];
    eveningShift: { userId: number; maxWorker: number }[];
    otherShift: { userId: number; maxWorker: number }[];
    unitPrice: { userId: number; price: number }[];
  };
  leader?: IWorker;
}

export interface ITempWorker {
  taskId: number;
  workerId: number;
  shifts: TShift[];
  task: ITask;
  isAddLeader?: boolean;
  isVendor?: boolean;
}

export type TWorkerDrop = IWorker & { shift: TShift; isAddLeader?: boolean };
export type TVendorDrop = VendorWorking & {
  shift: TShift;
  isVendorCompany: boolean;
  maxWorker: number;
  price?: number;
};
export type TItemVendor = VendorWorking & {
  shift: TShift[];
  isVendorCompany: boolean;
  maxWorker: number;
  price?: number;
};

export type TWorkerDrag = IWorker & {
  shift: TShift;
  isAddLeader?: boolean;
};

export interface IModalSelectShifts {
  open: boolean;
  taskId: number | null;
}

export interface IModalSelectVendor {
  open: boolean;
  taskId: number | null;
  vendorShifts: TShift[];
}

interface IDragWorkersStore {
  /* ------------------------ Track worker is dragging ------------------------ */
  workerDragging: IItemDropWorker | null;
  setWorkerDragging: (worker: IItemDropWorker | null) => void;
  dragLeave: boolean;
  setDragLeave: (dragLeave: boolean) => void;

  draggedWorker: TWorkerDrop | null;
  setDraggedWorker: (worker: TWorkerDrop | null) => void;

  setTempDefault: (data: ITaskTempRes[]) => void;

  /* ------------------------ Modal select shift ------------------------ */
  modalSelectShifts: IModalSelectShifts;
  setModalSelectShifts: ({ open, taskId }: IModalSelectShifts) => void;

  /* -------------------------- Modal select vendors -------------------------- */
  modalSelectVendor: IModalSelectVendor;
  setModalSelectVendor: ({ open, taskId, vendorShifts }: IModalSelectVendor) => void;

  /* ---------------------------------- Tasks --------------------------------- */
  tempTasks: {
    [taskId: number]: ITempTask;
  };
  addSingleTempTask: ({
    task,
    worker,
    isAddLeader,
  }: {
    task: ITask;
    worker: TWorkerDrop | TVendorDrop;
    isAddLeader?: boolean;
  }) => void;
  removeSingleTempTask: ({ taskId }: { taskId: number }) => void;
  removeAllTempTask: () => void;

  /* ------------------------------ Temp Workers task ------------------------------ */
  tempWorkers: {
    [workerId: number]: ITempWorker[];
  };
  removeOneTempWorkerFromTask: ({
    workerId,
    taskId,
    shift,
  }: {
    workerId: number;
    taskId: number;
    shift: TShift;
  }) => void;
  removeLeaderFromTask: ({ taskId }: { taskId: number }) => void;

  /* ---------------------------------Temp Vendors Task-------------------------------- */
  tempVendors: {
    [vendorId: number]: ITempWorker[];
  };
  removeOneTempVendorFromTask: ({
    taskId,
    workerId,
    shift,
  }: {
    taskId: number;
    workerId: number;
    shift: TShift;
  }) => void;
}

export const useDragWorkersStore = create(
  immer<IDragWorkersStore>((set, get) => ({
    /* ------------------------ Modal select shift ------------------------ */
    modalSelectShifts: { open: false, taskId: null },
    setModalSelectShifts: ({ open, taskId }: IModalSelectShifts) => {
      set((state) => {
        state.modalSelectShifts.open = open;
        state.modalSelectShifts.taskId = taskId;
      });
    },

    /* -------------------------- Modal select vendors -------------------------- */
    modalSelectVendor: { open: false, taskId: null, vendorShifts: [] },
    setModalSelectVendor: ({ open, taskId, vendorShifts }: IModalSelectVendor) => {
      set((state) => {
        state.modalSelectVendor.open = open;
        state.modalSelectVendor.taskId = taskId;
        state.modalSelectVendor.vendorShifts = vendorShifts;
      });
    },

    /* -------------------------------- Temp tasks ------------------------------- */
    tempTasks: {},
    setTempDefault: (data: ITaskTempRes[]) => {
      set((state) => {
        const dataLocal = convertDataResponseToLocal(data);
        state.tempTasks = dataLocal.tempTask;
        state.tempWorkers = dataLocal.tempWorkers;
        state.tempVendors = dataLocal.tempVendors;
      });
    },
    removeAllTempTask: () => {
      set((state) => {
        // reset all state
        state.tempWorkers = {};
        state.tempTasks = {};
        state.tempVendors = {};
        handleUpdateTempTasks({ dataTermTaskWorker: [] });
      });
    },
    removeSingleTempTask: ({ taskId }: { taskId: number }) => {
      set((state) => {
        const tempTask = state.tempTasks[taskId];

        if (!tempTask) return;

        // delete temp workers
        for (const shift of ['morningShift', 'afternoonShift', 'eveningShift', 'otherShift'] as TShift[]) {
          if (!tempTask.workers[shift]) continue;

          for (const worker of tempTask.workers[shift]) {
            removeShiftFromTempWorker({
              state,
              taskId,
              workerId: worker.id,
              shift,
            });
          }
        }

        // delete temp vendors
        for (const shift of ['morningShift', 'afternoonShift', 'eveningShift', 'otherShift'] as TShift[]) {
          if (!tempTask.vendors[shift]) continue;

          for (const vendors of tempTask.vendors[shift]) {
            removeShiftFromTempVendor({ state, taskId, workerId: vendors.userId, shift });
          }
        }
        // delete task
        delete state.tempTasks[taskId];
        const params = convertDataToParams(state);
        handleUpdateTempTasks({ dataTermTaskWorker: params });
      });
    },
    addSingleTempTask: ({ task, worker, isAddLeader }) => {
      set((state) => {
        if (worker.isVendorCompany) {
          addTempVendorToTask({ state, task, worker: worker as any });
          addTaskToTempVendor({ state, task, worker: worker as any });
        } else if (isAddLeader) {
          addTempWorkerToTask({ state, task, worker: worker as any, isAddLeader });
        } else {
          addTempWorkerToTask({ state, task, worker: worker as any });
          addTaskToTempWorker({ state, task, worker: worker as any });
        }
        console.log(get());
        const params = convertDataToParams(state);
        handleUpdateTempTasks({ dataTermTaskWorker: params });
      });
    },

    /* ---------------------------- Temp Workers task --------------------------- */
    tempWorkers: {},

    removeOneTempWorkerFromTask: ({ workerId, taskId, shift }) => {
      set((state) => {
        removeTempWorkerFromTask({ state, taskId, workerId, shift });

        removeShiftFromTempWorker({
          state,
          taskId,
          workerId,
          shift,
        });
        const params = convertDataToParams(state);
        handleUpdateTempTasks({ dataTermTaskWorker: params });
      });
    },
    removeLeaderFromTask: ({ taskId }) => {
      set((state) => {
        state.tempTasks[taskId].leader = undefined;

        // check if task has no workers left, remove task from tempTasks
        const isTaskNotHaveWorker =
          state.tempTasks[taskId].workers.morningShift.length === 0 &&
          state.tempTasks[taskId].workers.afternoonShift.length === 0 &&
          state.tempTasks[taskId].workers.eveningShift.length === 0 &&
          state.tempTasks[taskId].workers.otherShift.length === 0;
        const isTaskNotHaveVendor = !state?.tempTasks?.[taskId]?.vendors?.list?.length;

        if (isTaskNotHaveWorker && isTaskNotHaveVendor) {
        }
        const params = convertDataToParams(state);
        handleUpdateTempTasks({ dataTermTaskWorker: params });
      });
    },

    /* ------------------------------Temp Vendors task ------------------------------ */
    tempVendors: {},
    removeOneTempVendorFromTask({ taskId, workerId, shift }) {
      set((state) => {
        removeTempVendorFromTask({ state, taskId, workerId, shift });

        removeShiftFromTempVendor({ state, taskId, workerId, shift });
        const params = convertDataToParams(state);
        handleUpdateTempTasks({ dataTermTaskWorker: params });
      });
    },

    /* ------------------------ Track worker is dragging ------------------------ */
    workerDragging: null,
    setWorkerDragging(worker) {
      set((state) => {
        state.workerDragging = worker;
      });
    },
    draggedWorker: null,
    setDraggedWorker(worker) {
      set((state) => {
        state.draggedWorker = worker;
      });
    },

    /* ---------------------- Detect if drag leave tooltip ---------------------- */
    dragLeave: false,
    setDragLeave: (dragLeave) => {
      set((state) => {
        state.dragLeave = dragLeave;
      });
    },
  }))
);

export const isDropVendorCompany = (workerDrop: IItemDropWorker) => {
  return workerDrop?.roleId === UserRole.VENDOR;
};

export const isDragVendorCompany = (workerDrag: IWorker) => {
  return workerDrag?.roleId === UserRole.VENDOR;
};

const addTaskToTempWorker = ({
  state,
  task,
  worker,
}: {
  state: IDragWorkersStore;
  task: ITask;
  worker: TWorkerDrop;
}) => {
  if (!state.tempWorkers[worker.id]) {
    state.tempWorkers[worker.id] = [];
  }
  const tempWorker = state.tempWorkers[worker.id].find((t) => t.taskId === task.id);
  if (tempWorker) {
    tempWorker.shifts.push(worker.shift);
  } else {
    state.tempWorkers[worker.id].push({
      taskId: task.id,
      workerId: worker.id,
      shifts: [worker.shift],
      task,
    });
  }
};

const removeShiftFromTempWorker = ({
  state,
  taskId,
  workerId,
  shift,
}: {
  state: IDragWorkersStore;
  taskId: number;
  workerId: number;
  shift: TShift;
}) => {
  const tempWorker = state.tempWorkers[workerId].find((t) => t.taskId === taskId);
  if (tempWorker) {
    tempWorker.shifts = tempWorker.shifts.filter((s) => s !== shift);
    if (tempWorker.shifts.length === 0) {
      state.tempWorkers[workerId] = state.tempWorkers[workerId].filter((t) => t.taskId !== taskId);

      if (state.tempWorkers[workerId].length === 0) {
        delete state.tempWorkers[workerId];
      }
    }
  }
};

const addTempWorkerToTask = ({
  state,
  task,
  worker,
  isAddLeader,
}: {
  state: IDragWorkersStore;
  task: ITask;
  worker: TWorkerDrop;
  isAddLeader?: boolean;
}) => {
  const isExistsTempTask = state.tempTasks.hasOwnProperty(task.id);
  /* ------------------------ logic add leader to task ------------------------ */
  // when add leader to task, worker always already exists in tempTasks or final tasks
  if (isAddLeader) {
    if (isExistsTempTask) {
      state.tempTasks[task.id].leader = worker;
    } else {
      state.tempTasks[task.id] = {
        vendors: state?.tempTasks?.[task?.id]?.vendors ?? {},
        companyId: task.companyId,
        taskId: task.id,
        workers: {
          morningShift: [],
          afternoonShift: [],
          eveningShift: [],
          otherShift: [],
          unitPrice: [],
        },
        leader: worker,
      };
    }

    return;
  }

  /* ------------------------ logic add worker to task ------------------------ */

  // calculate unit price
  const unitPrice: IUnitPriceWorker[] = task.workerPrice.map((item) => ({
    userId: item?.id,
    price: item?.price ?? 0,
  }));

  if (isExistsTempTask) {
    state.tempTasks[task.id].workers[worker.shift].push(worker);
    state.tempTasks[task.id].workers.unitPrice = unitPrice;
  } else {
    state.tempTasks[task.id] = {
      vendors: state?.tempTasks?.[task?.id]?.vendors ?? {},
      companyId: task.companyId,
      taskId: task.id,
      workers: {
        morningShift: [],
        afternoonShift: [],
        eveningShift: [],
        otherShift: [],
        unitPrice,
      },
    };

    // add worker to task
    if (worker.shift) {
      state.tempTasks[task.id].workers[worker.shift].push(worker);
    }
  }
};

const removeTempWorkerFromTask = ({
  state,
  taskId,
  workerId,
  shift,
}: {
  state: IDragWorkersStore;
  taskId: number;
  workerId: number;
  shift: TShift;
}) => {
  // Remove worker from task
  state.tempTasks[taskId].workers[shift] = state.tempTasks[taskId].workers[shift].filter(
    (worker) => worker.id !== workerId
  );

  // If task has no workers left, remove task from tempTasks
  const isTaskNotHaveWorker =
    state.tempTasks[taskId].workers.morningShift.length === 0 &&
    state.tempTasks[taskId].workers.afternoonShift.length === 0 &&
    state.tempTasks[taskId].workers.eveningShift.length === 0 &&
    state.tempTasks[taskId].workers.otherShift.length === 0;

  if (isTaskNotHaveWorker) {
    delete state.tempTasks[taskId];
  }
};

const addTempVendorToTask = ({
  state,
  task,
  worker,
}: {
  state: IDragWorkersStore;
  task: ITask;
  worker: TVendorDrop;
}) => {
  const isExistsTempTask = state.tempTasks.hasOwnProperty(task.id) && !!state.tempTasks[task.id]?.vendors?.list?.length;
  const isOtherShift = worker.shift === 'otherShift';
  const isExistsVendorInTask = state?.tempTasks?.[task.id]?.vendors?.list?.some((vendor) => vendor.id === worker.id);
  const unitPrice: { userId: number; price: number } = {
    userId: worker.id,
    price: worker.price ?? 0,
  };

  // update task
  if (isExistsTempTask) {
    // if other shift -> update to unit price
    if (isOtherShift) {
      state?.tempTasks?.[task?.id]?.vendors?.unitPrice.push(unitPrice);
    }

    // update vendor to list
    if (isExistsVendorInTask) {
      state?.tempTasks?.[task?.id]?.vendors?.list?.find((vendor) => vendor.id === worker.id)?.shift.push(worker.shift);
    } else {
      state?.tempTasks?.[task?.id]?.vendors?.list?.push({
        ...(worker as any),
        shift: [worker.shift],
      });
    }

    // update vendor to shift
    state?.tempTasks?.[task?.id]?.vendors?.[worker.shift as TShift].push({
      userId: worker.id,
      maxWorker: worker.maxWorker,
    });

    return;
  }

  // create new task

  state.tempTasks[task.id] = {
    ...state?.tempTasks?.[task?.id],
    workers: {
      morningShift: state?.tempTasks?.[task?.id]?.workers?.morningShift ?? [],
      afternoonShift: state?.tempTasks?.[task?.id]?.workers?.afternoonShift ?? [],
      eveningShift: state?.tempTasks?.[task?.id]?.workers?.eveningShift ?? [],
      otherShift: state?.tempTasks?.[task?.id]?.workers?.otherShift ?? [],
      unitPrice: state?.tempTasks?.[task?.id]?.workers?.unitPrice ?? [],
    },
    vendors: {
      list: [],
      morningShift: [],
      afternoonShift: [],
      eveningShift: [],
      otherShift: [],
      unitPrice: [],
    },
    companyId: task.companyId,
    taskId: task.id,
  };

  if (!isExistsVendorInTask) {
    state?.tempTasks?.[task?.id].vendors?.list.push({
      ...(worker as any),
      shift: [worker.shift],
    });
  }

  if (isOtherShift) {
    state?.tempTasks?.[task?.id]?.vendors?.otherShift.push({
      userId: worker.id,
      maxWorker: worker.maxWorker,
    });

    state?.tempTasks?.[task?.id]?.vendors?.unitPrice?.push(unitPrice);

    return;
  }

  state?.tempTasks?.[task?.id]?.vendors?.[worker?.shift as TShift]?.push({
    userId: worker.id,
    maxWorker: worker.maxWorker,
  });
};

const removeTempVendorFromTask = ({
  state,
  taskId,
  workerId,
  shift,
}: {
  state: IDragWorkersStore;
  taskId: number;
  workerId: number;
  shift: TShift;
}) => {
  // remove vendor out of shift
  if (!isNil(state?.tempTasks?.[taskId]?.vendors?.[shift])) {
    (state as any).tempTasks[taskId].vendors[shift] =
      (state as any)?.tempTasks?.[taskId]?.vendors[shift]?.filter((vendor: any) => vendor.userId !== workerId) ?? [];
  }

  // remove vendor out of unit price
  if (shift === 'otherShift' && !isNil(state?.tempTasks?.[taskId]?.vendors?.unitPrice)) {
    (state as any).tempTasks[taskId].vendors.unitPrice = (state as any)?.tempTasks?.[taskId]?.vendors.unitPrice?.filter(
      (vendor: any) => vendor.userId !== workerId
    );
  }

  // remove shift out of list vendor
  const vendor = state?.tempTasks?.[taskId]?.vendors?.list?.find((vendor) => vendor.id === workerId);
  const newShifts = state?.tempTasks?.[taskId]?.vendors?.list
    ?.find((vendor) => vendor.id === workerId)
    ?.shift?.filter((s) => s !== shift);

  // update new shift to vendor
  if (vendor) {
    (vendor as any).shift = newShifts;
  }

  // if new shift is empty -> remove vendor out of list
  if (newShifts?.length === 0) {
    (state as any).tempTasks[taskId].vendors.list = (state as any)?.tempTasks?.[taskId]?.vendors.list?.filter(
      (vendor: IVendor) => vendor.id !== workerId
    );
  }
};

const addTaskToTempVendor = ({
  state,
  task,
  worker,
}: {
  state: IDragWorkersStore;
  task: ITask;
  worker: TVendorDrop;
}) => {
  if (!state.tempVendors[worker.id]) {
    state.tempVendors[worker.id] = [];
  }

  const tempVendor = state.tempVendors[worker.id].find((t) => t.taskId === task.id);

  if (tempVendor) {
    tempVendor.shifts.push(worker.shift);
  } else {
    state.tempVendors[worker.id].push({
      taskId: task.id,
      workerId: worker.id,
      shifts: [worker.shift],
      task,
    });
  }
};

const removeShiftFromTempVendor = ({
  state,
  taskId,
  workerId,
  shift,
}: {
  state: IDragWorkersStore;
  taskId: number;
  workerId: number;
  shift: TShift;
}) => {
  const tempVendor = state?.tempVendors?.[workerId]?.find((t) => t.taskId === taskId);
  if (tempVendor) {
    tempVendor.shifts = tempVendor?.shifts?.filter((s) => s !== shift);
    if (tempVendor?.shifts?.length === 0) {
      state.tempVendors[workerId] = state?.tempVendors?.[workerId]?.filter((t) => t.taskId !== taskId);

      if (state?.tempVendors?.[workerId]?.length === 0) {
        delete state.tempVendors[workerId];
      }
    }
  }
};

const convertDataToParams = (dataTemp: {
  tempTasks: {
    [taskId: number]: ITempTask;
  };
  tempWorkers: {
    [workerId: number]: ITempWorker[];
  };
  tempVendors: {
    [vendorId: number]: ITempWorker[];
  };
}) => {
  const taskIds = Object.keys(dataTemp?.tempTasks);
  const workers = Object.values(dataTemp.tempWorkers).flat();
  const vendors = Object.values(dataTemp.tempVendors)
    .flat()
    ?.map((vendor) => ({ ...vendor, isVendor: true }));
  const usersTask = [...workers, ...vendors];
  const tasksData = taskIds.map((taskId) => {
    const usersInTask = usersTask.filter((user) => user.taskId === Number(taskId));
    return {
      taskId: Number(taskId),
      metadataTask: JSON.stringify(dataTemp.tempTasks[Number(taskId)]),
      dataUser: usersInTask.map((user) => ({
        userId: user.workerId,
        metadata: JSON.stringify(user),
      })),
    };
  });
  return tasksData;
};

const convertDataResponseToLocal = (data: ITaskTempRes[]) => {
  const store = data.reduce(
    (
      acc: {
        tempTask: Record<number, ITempTask>;
        tempWorkers: Record<number, ITempWorker[]>;
        tempVendors: Record<number, ITempWorker[]>;
      },
      task
    ) => {
      acc.tempTask[task.taskId] = task.metadataTask;
      const users = task.dataUser.filter((user) => !user.metadata?.isVendor);
      const vendors = task.dataUser.filter((user) => user.metadata?.isVendor);
      users.forEach((user) => {
        if (!acc.tempWorkers[user.userId]) {
          acc.tempWorkers[user.userId] = [user.metadata];
        } else {
          acc.tempWorkers[user.userId] = [...acc.tempWorkers[user.userId], user.metadata];
        }
      });
      vendors.forEach((vendor) => {
        if (!acc.tempVendors[vendor.userId]) {
          acc.tempVendors[vendor.userId] = [vendor.metadata];
        } else {
          acc.tempVendors[vendor.userId] = [...acc.tempVendors[vendor.userId], vendor.metadata];
        }
      });
      return acc;
    },
    {
      tempTask: {} as Record<number, ITempTask>,
      tempWorkers: {} as Record<number, ITempWorker[]>,
      tempVendors: {} as Record<number, ITempWorker[]>,
    }
  );
  return store;
};

const handleUpdateTempTasks = async (dataTemp: ITermTaskWorker) => {
  try {
    await updateTempTasks(dataTemp);
  } catch (error) {
    console.log(error);
  }
};
