const queryKeys = {
  listVendorWorking: 'list-vendor-working',
  detailsEdit: 'details-edit',
  constructionSiteTaskDetail: 'construction-site-task-detail',
  listConstructorSite: 'list-cost-constructor-site',
  calendarDetailWorkerReport: 'calendar-detail-worker-report',
  calendarWorkerReport: 'calendar-worker-report',
  ListCommentWorkerReport: 'list-comment-worker-report',
  ListOtherMissing: 'list-other-missing',
  ListCompanyMissing: 'list-company-missing',
  listPaper: 'list-paper',
  newComment: 'new-comment',
  menuSideBar: 'menu-side-bar',
  profile: 'profile',
  inviteStaffToken: 'inviteStaffToken',
  customerDetail: 'customer-detail',
  detailVendor: 'detailVendor',
  getUserSchedule: 'get-user-schedule',
  bonus: {
    list: 'list-bonus',
  },
  vendors: {
    searchCommon: 'commonVendor',
    listVendor: 'list-vendor',
    listVendorByCompany: 'list-vendor-by-company',
  },
  common: {
    workerBasicSalary: 'worker-basic-salary',
    list_common_user: 'list_common_user',
  },
  salary: {
    listTimeSheet: 'list-time-sheet',
  },
  task: {
    list_constructionSite__create_task: 'list_constructionSite__create_task',
  },
  profileKey: {
    getCompanySubsidyV6: 'getCompanySubsidyV6',
  },
  report: {
    revenue: 'report-revenue',
  },
  calendar: {
    'list-tasks--home-calendar': 'list-tasks--home-calendar',
    listTask: 'list-task',
    totalTasksInShift: 'total-tasks-in-shift',
  },
  construction: {
    listProject: 'list-project',
  },
  schedule: {
    'count-schedule-not-confirm': 'count-schedule-not-confirm',
    listSchedule: 'list-schedule',
    countOfficeWorkers: 'countOfficeWorkers',
  },
  holiday: {
    listHoliday: 'list-holiday',
  },
  company: {
    list: 'commonCompany',
    listColor: 'list-color-company',
    listCompanyActive: 'list-company-active',
  },
  customer: {
    listCustomer: 'list-customer',
    listCustomerMultiCompany: 'list-customer-multi-company',
  },
  insurance: {
    incomeTaxBonusConfig: 'income-tax-bonus-config',
    unemploymentEmployeeConfig: 'unemployment-employee-config',
    healthInsuranceBonusConfig: 'health-insurance-bonus-config',
  },
  staff: {
    listStaff: 'list-staff',
  },
  notes: {
    list: 'list-notes',
    cacheNotes: 'cache-notes',
  },
  timeOff: {
    detail: 'time-off-detail',
    history: 'time-off-history',
  },
  campaign: {
    list: 'list-campaign',
    details: 'details-campaign',
  },
  plan: {
    list: 'list-plan',
  },
  timekeeping: {
    hasCheckInTask: 'has-check-in-task',
  },
} as const;

export default queryKeys;
