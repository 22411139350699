import { Checkbox, Form, Space } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { messageError } from 'common/const';
import LabelRequired from 'components/Form/LabelRequired';
import { validateOfficeWorker } from 'helper/validator';
// import React from 'react';

interface IProps {
  disable?: boolean;
  form?: FormInstance;
}

const CheckboxPlaceWork = ({ disable, form }: IProps) => {
  return (
    <>
      <LabelRequired label="オフィス内" />

      <Space align="start">
        {/* Trong văn phòng */}
        <Form.Item
          valuePropName="checked"
          label=""
          name={['metadata', 'isWorkOffice']}
          initialValue={false}
          className="mb-0"
        >
          <Checkbox disabled={disable}>内勤</Checkbox>
        </Form.Item>

        {/* Ngoài văn phòng */}
        <Form.Item
          valuePropName="checked"
          label=""
          name={['metadata', 'isWorkConstructionSite']}
          initialValue={true}
          className="mb-0"
        >
          <Checkbox disabled={disable}>外勤</Checkbox>
        </Form.Item>
      </Space>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const isWorkOffice = getFieldValue(['metadata', 'isWorkOffice']);
          const isWorkConstructionSite = getFieldValue(['metadata', 'isWorkConstructionSite']);

          if (!validateOfficeWorker({ isWorkConstructionSite, isWorkOffice })) {
            return <div className="text-error fontsize-13 mb-9">{messageError.requiredField}</div>;
          }

          return <div className="mb-9"></div>;
        }}
      </Form.Item>
    </>
  );
};

export default CheckboxPlaceWork;
