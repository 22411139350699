import { Form, Row, Col } from 'antd';
import { BIG_INPUT_NUMBER } from 'common/const';
import icAdd from 'assets/icons/plus-gray.svg';
import icDelete from 'assets/icons/trash-2.svg';
import { CommonIconButton } from 'components/CommonIconButton';
import { CommonInput } from 'components/CommonInput';
import { CommonInputNumber } from 'components/CommonInputNumber';

import { validateMaxMinInput } from 'helper/validator';
import { ISubsidy } from 'common/interface';
import { useRulesForm } from 'hooks/useRulesForm';
import LabelRequired from 'components/Form/LabelRequired';
import CommonSpace from 'components/CommonSpace';

interface IProps {
  disabled: boolean;
  otherSubsidy: ISubsidy[];
}
export const ListSubsidy = ({ disabled, otherSubsidy }: IProps) => {
  const { rulesForm } = useRulesForm();

  return (
    <Form.List name={['metadata', 'otherSubsidy']}>
      {(fields, { add, remove }) => {
        return (
          <>
            <CommonSpace gap={10}>
              <LabelRequired label="手当の金額" isRequired={false} />

              {!disabled && (
                <CommonIconButton
                  icon={icAdd}
                  onClick={() => {
                    add();
                  }}
                />
              )}
            </CommonSpace>

            <Row gutter={20} className="d-flex align-items-center mt-8">
              {otherSubsidy?.map((el: ISubsidy) => (
                <>
                  <Col span={12}>
                    <Form.Item
                      // label="手当の金額"
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <CommonInput disabled={true} value={el?.title} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <CommonInput disabled={true} value={el?.subsidy} />
                    </Form.Item>
                  </Col>
                </>
              ))}

              {fields.map(({ key, name, fieldKey, ...rest }, index) => (
                <>
                  <Col span={12}>
                    <Form.Item
                      {...rest}
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                      rules={[rulesForm().required]}
                    >
                      <CommonInput disabled={disabled} placeholder="タイトル" />
                    </Form.Item>
                  </Col>

                  <Col span={10}>
                    <Form.Item
                      {...rest}
                      name={[name, 'subsidy']}
                      fieldKey={[fieldKey, 'subsidy']}
                      rules={[rulesForm().required, validateMaxMinInput(BIG_INPUT_NUMBER, 0)]}
                      validateFirst
                    >
                      <CommonInputNumber disabled={disabled} placeholder="金額" />
                    </Form.Item>
                  </Col>

                  {!disabled && (
                    <Col span={2}>
                      <CommonIconButton
                        icon={icDelete}
                        onClick={() => {
                          remove(name);
                        }}
                        disabled={disabled}
                        style={{
                          position: 'absolute',
                          top: '-30px',
                        }}
                      />
                    </Col>
                  )}
                </>
              ))}
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};
