import { FormInstance } from 'antd';
import { IResConstructionSiteDetail } from 'common/interface';
import React from 'react';

interface ChangeProjectProps {
  form: FormInstance;
  selectedVendor: any[];
  oldConstructionSiteRef: any;
  setTaskDetail: React.Dispatch<React.SetStateAction<any>>;
  setSelectedVendor: React.Dispatch<React.SetStateAction<any>>;
  data: IResConstructionSiteDetail;
}

const useChangeProject = () => {
  const handleChangeProject = ({
    form,
    selectedVendor,
    oldConstructionSiteRef,
    setTaskDetail,
    setSelectedVendor,
    data,
  }: ChangeProjectProps) => {
    // if customer of task change
    const isChangeCustomer = oldConstructionSiteRef.current.customerId !== data?.data?.customerId;

    if (isChangeCustomer) {
      // reset certificate of customer
      form.setFieldsValue({
        morning: undefined,
        afternoon: undefined,
        evening: undefined,
        other: undefined,
      });
    }

    // update construction site type
    const constructionSiteType = data?.data?.type;
    setTaskDetail((prev: any) => ({ ...prev, constructionSiteType }));

    const constructionVendors = data?.data?.vendors;
    const constructionWorkers = data?.data?.workers;
    const constructionVendorsIds = constructionVendors?.map((x: any) => x.id);

    // check if vendors and workers in snapshot belong to construction site
    // if not, remove them out of form value
    const workerIdsMorning = form.getFieldValue('workerIdsMorning');
    const workerIdsAfternoon = form.getFieldValue('workerIdsAfternoon');
    const workerIdsEvening = form.getFieldValue('workerIdsEvening');
    const workerIdsOther = form.getFieldValue('workerIdsOther');

    const vendorsMorning = form.getFieldValue('vendorsMorning');
    const vendorsAfternoon = form.getFieldValue('vendorsAfternoon');
    const vendorsEvening = form.getFieldValue('vendorsEvening');
    const vendorsOther = form.getFieldValue('vendorsOther');

    const workerIdsMorningFilter = workerIdsMorning?.filter((x: number) =>
      constructionWorkers?.find((worker: any) => worker.id === x)
    );
    const workerIdsAfternoonFilter = workerIdsAfternoon?.filter((x: number) =>
      constructionWorkers?.find((worker: any) => worker.id === x)
    );
    const workerIdsEveningFilter = workerIdsEvening?.filter((x: number) =>
      constructionWorkers?.find((worker: any) => worker.id === x)
    );
    const workerIdsOtherFilter = workerIdsOther?.filter((x: number) =>
      constructionWorkers?.find((worker: any) => worker.id === x)
    );

    const vendorsMorningFilter = vendorsMorning?.filter((x: any) =>
      constructionVendors?.find((vendor: any) => vendor.id === x.id)
    );
    const vendorsAfternoonFilter = vendorsAfternoon?.filter((x: any) =>
      constructionVendors?.find((vendor: any) => vendor.id === x.id)
    );
    const vendorsEveningFilter = vendorsEvening?.filter((x: any) =>
      constructionVendors?.find((vendor: any) => vendor.id === x.id)
    );
    const vendorsOtherFilter = vendorsOther?.filter((x: any) =>
      constructionVendors?.find((vendor: any) => vendor.id === x.id)
    );

    // remove certificate of vendor not belong to construction site
    const selectedVendorFilter = selectedVendor.filter((vendor) => constructionVendorsIds.includes(vendor.id));
    setSelectedVendor(selectedVendorFilter);

    form.setFieldsValue({
      noteConstructionSite: data?.data?.description ?? '', // update note of construction site
      workerIdsMorning: workerIdsMorningFilter,
      workerIdsAfternoon: workerIdsAfternoonFilter,
      workerIdsEvening: workerIdsEveningFilter,
      workerIdsOther: workerIdsOtherFilter,
      vendorsMorning: vendorsMorningFilter,
      vendorsAfternoon: vendorsAfternoonFilter,
      vendorsEvening: vendorsEveningFilter,
      vendorsOther: vendorsOtherFilter,
    });
  };

  return { handleChangeProject };
};

export default useChangeProject;
